import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [reachOutMessage, setReachOutMessage] = useState("");
  const [formData, setFormData] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleEmail = async () => {
    setButtonLoading(true);
    await new Promise((r) => setTimeout(r, 2000));
    setButtonLoading(false);
    setReachOutMessage("We will reach out to you asap.");
    setIsRevealed(true);
    setFormData("");
  };

  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">
              Drop us an email and we will have someone reach out to you.
            </h3>
          </div>
          <div className="cta-action" id="contact">
            {reachOutMessage && (
              <p
                className={`reveal-from-bottom" ${isRevealed && "is-revealed"}`}
                data-reveal-delay="200"
              >
                We will reach out to you asap.
              </p>
            )}
            <Input
              id="newsletter"
              type="email"
              label="Subscribe"
              labelHidden
              hasIcon="right"
              placeholder="Your email"
              value={formData}
              onChange={(event) => setFormData(event.target.value)}
            >
              <button
                className={`button-block button button-dark button-sm mt-16 ${
                  buttonLoading && "is-loading"
                } ${isRevealed && "is-revealed"}`}
                onClick={handleEmail}
                disabled={!validateEmail(formData)}
              >
                Submit
              </button>
            </Input>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
